const translation = {
    en: {
        navItems: {
            home: "Home",
            aboutUs: "About Us",
            features: "Features",
            pricing: "Pricing",
            contact: "Contact Us",
            howTo: "How To",
            trialBtn: "Try VPN server for free"
        },
        main: {
            main: {
                question: "Are you ready to get your own VPN server?",
                description: "Create your own VPN server in a minute!",
                btn: "Ready to start"
            },
            aboutUs: {
                heading: "About Us",
                description: "Cloud Dacha helps technology to be comfortable and cozy in everyday life. No long settings, articles and unnecessary documentation.",
                forWhom: {
                    heading: "Your cozy VPN server",
                    description: "For those who use VPN services on a daily basis and want to go to the next level - their own VPN server.",
                    security: {
                        heading: "Security",
                        description: "Your transmitted personal information is protected when connected via VPN even to public Wi-Fi networks.",
                    },
                    anonymous: {
                        heading: "Anonymous",
                        description: "Location of your device changes to the location of the VPN server.",
                    },
                    restriction: {
                        heading: "Restriction",
                        description: "Access to personal resources is easily restricted in your private network."
                    }
                },
                telegramBot: {
                    heading: "Telegram Bot",
                    description: "An easy way to interact with the application is to use Telegram.",
                    link: "Get Telegram"
                }
            },
            features: {
                heading: "Our Features",
                description: "Get all the features of your VPN server in a minute!",
                location: {
                    heading: "Choose a location",
                    description: "Turkey, Germany, Russia, Kazakhstan, Netherlands and more",
                },
                share: {
                    heading: "Share",
                    description: "Invite friends or coworkers to your own server",
                },
                size: {
                    heading: "Public accessibility",
                    description: "One free connection to public servers in Russia, Kazakhstan and Germany",
                }
            },
            pricing: {
                heading: "Pricing",
                description: "Pay with a credit card",
                location: "Any location",
                currency: "RUB",
                perMonth: "per month",
                per6Months: "per 6 months",
                perYear: "per year",
                marketing: "Pay a year subscription and get 3 months for free! (up to 25% discount)",
                free: {
                    heading: "Personal",
                    description: "Your personal VPN server with basic technical support and customer service.",
                    inAddition: "+ 149 RUB for additional user",
                    duration: "1 month",
                    users: "5 connections",
                    tryBtn: "Subscribe",
                },
                personal: {
                    heading: "Family",
                    description: "Share your VPN server with your family and friends.",
                    inAddition: "+ 149 RUB for additional user",
                    duration: "1 month",
                    users: "10 connections",
                    buyBtn: "Subscribe",
                    tryBtn: "7 days for free",
                },
                corporate: {
                    heading: "Corporate",
                    description: "Extended technical support and customer service.",
                    inAddition: "+ 299 RUB for additional user",
                    duration: "1 month",
                    users: "100 connections",
                    buyBtn: "Subscribe",
                }
            },
            howTo: {
                heading: "How To Use",
                description: "Articles on how to use our service.",
                hint1: "More detailed articles on how to use our service can be found on the ",
                hint2: " page.",
            },
        },
        howTo: {
            heading: "How To Use",
            description: "Articles on how to use our service",
            description2: "Choose section to know more about topic",
            section1: {
                heading: "Fisrt steps in bot:",
                start: {
                    heading: "Start using our bot",
                    description: "Click button below and send /start message to the bot.",
                    btn: "Start Using Bot",
                    timing: "15 sec",
                },
                create: {
                    heading: "Create your server",
                    description: "Use the /servers command and follow the bot's instructions. It'll take a minute.",
                    btn: "Follow Bot",
                    timing: "1 min",
                },
                getConfig: {
                    heading: "Get configuration to connect",
                    description: "Use /configs command and follow bot instructions.",
                    btn: "Get Your Config",
                    timing: "30 sec",
                }
            },
            section2: {
                heading: "Setup OpenVPN Connect application on your device:",
                result: "Congratulations! You are connected to server.",
                download: {
                    heading: "Download Open VPN Connect",
                    description: "It is application to connect to your server. Download specific version depending of your device.",
                    timing: "2 min",
                },
                notice: {
                    heading: "Notice:",
                    description: "Your should download and install OpenVPN Connect first.",
                },
                setupIphone: {
                    heading: "Setup connect to server in IPhone",
                    instruction: {
                        item1: "1. Open OpenVPN configuration from bot.",
                        item2: "2. Click \"share\" symbol.",
                        item3: "3. Choose OpenVPN Connect application.",
                        item4: "4. Click \"ADD\" and then \"CONNECT\".",
                    },
                    timing: "1 min",
                    
                },
                setupAndroid: {
                    heading: "Setup connect to server in Android",
                    instruction: {
                        item1: "1. Save to downloads the OpenVPN configuration from bot.",
                        item2: "2. Open the OpenVPN Connect application.",
                        item3: "3. Select the \"FILE\" tab.",
                        item4: "4. Select the \"Download\" folder from list and then \"Telegram\".",
                        item5: "5. Select the downloaded configuration file.",
                        item6: "6. Click the \"IMPORT\" button and then \"ADD\".",
                        item7: "7. Click the toggle to connect via your configuration.",
                        item8: "8. Approve the connection request.",
                    },
                    timing: "1 min",
                },
                setupMacOs: {
                    heading: "Setup connect to server in MacOS",
                    instruction: {
                        item1: "1. Open the OpenVPN Connect application.",
                        item2: "2. Click \"+\" to add profile.",
                        item3: "3. Select the \"FILE\" tab.",
                        item4: "4. Download the OpenVPN configuration from bot.",
                        item5: "5. Click \"Show in Finder\".",
                        item6: "6. Drag and drop configuration file to Open VPN Connect.",
                        item7: "7. Click the \"CONNECT\" button.",
                    },
                    timing: "1 min",
                },
                setupWindows: {
                    heading: "Setup connect to server in Windows",
                    instruction: {
                        item1: "1. Download the OpenVPN configuration from bot.",
                        item2: "2. Make a right click on file icon.",
                        item3: "3. Select \"Show in folder\".",
                        item4: "4. Make a right click on file.",
                        item5: "5. Select \"Open with\" and then OpenVPN Connect.",
                        item6: "6. Click \"OK\".",
                        item7: "7. Click the \"CONNECT\" button.",
                    },
                    timing: "1 min",
                },
            },
        },
        contacts: {
            heading: "Our contacts",
            description: "Contact to us and we will be happy to answer you.",
            telegram: {
                heading: "Are You Facing Any Problem..?",
                description: "We will help you!",
                btn: "Start Support Telegram Chat",
            },
            support: {
                heading: "Have suggestions or feedback?",
                description: "Contact our support.",
            },
            sales: {
                heading: "Want a custom subscription?",
                description: "Or do you have questions about payments? Contact our sales.",
            }
        },
        footer: {
            heading: "Cloud Dacha helps technology to be comfortable and cozy in everyday life.",
            description: "No long settings, articles and unnecessary documentation.",
            learnMore: {
                heading: "Learn More",
            },
            customer: {
                heading: "Customer",
                pp: {
                    heading: "Privacy Policy",
                },
                tos: {
                    heading: "Terms Of Service",
                },
                eula: {
                    heading: "End User License Agreement",
                },
            },
        },
    },
    ru: {
        navItems: {
            home: "Главная",
            aboutUs: "О нас",
            features: "Функционал",
            pricing: "Цена",
            contact: "Контакты",
            howTo: "Инструкции",
            trialBtn: "Попробовать бесплатно"
        },
        main: {
            main: {
                question: "Готовы получить собственный VPN-сервер?",
                description: "Создайте свой собственный VPN-сервер за минуту!",
                btn: "Вперед!"
            },
            aboutUs: {
                heading: "О нас",
                description: "Cloud Dacha помогает технологиям быть комфортными и уютными в повседневной жизни. Без долгих настроек, статей и дополнительной документации.",
                forWhom: {
                    heading: "Ваш уютный VPN-сервер",
                    description: "Для тех, кто использует VPN-сервисы каждый день, и хочет перейти на следующий уровень — свой собственный VPN-сервер.",
                    security: {
                        heading: "Безопасность",
                        description: "Персональные данные защищены, когда вы используете VPN, даже при подключении к публичным сетям Wi-FI.",
                    },
                    anonymous: {
                        heading: "Анонимность",
                        description: "Местоположение устройства меняется на местоположение VPN-сервера.",
                    },
                    restriction: {
                        heading: "Обособленность",
                        description: "Доступ к личным ресурсам можно легко ограничить в вашей частной сети (VPN)."
                    }
                },
                telegramBot: {
                    heading: "Telegram Бот",
                    description: "Простой способ взаимодействия с приложением через Telegram.",
                    link: "Скачать Telegram"
                }
            },
            features: {
                heading: "Функционал",
                description: "Получи все преимущества своего собственного VPN-сервера за минуту!",
                location: {
                    heading: "Выбор местоположения",
                    description: "Турция, Германия, Россия, Казахстан, Нидерланды и другие страны",
                },
                share: {
                    heading: "Возможность делиться",
                    description: "Приглашайте друзей или коллег на ваш собственный сервер",
                },
                size: {
                    heading: "Общедоступность",
                    description: "Одно бесплатное подключение на публичные VPN-серверы",
                }
            },
            pricing: {
                heading: "Цена",
                description: "Оплатите с помощью карты, SberPay, ЮМани",
                location: "Любое местоположение",
                currency: "руб.",
                perMonth: "в месяц",
                per6Months: "за 6 месяцев",
                perYear: "в год",
                marketing: "Оплатите годовую подписку и получите 3 месяца бесплатно! (скидка 25%)",
                free: {
                    description: "Ваш персональный VPN-сервер с базовым уровнем технической поддержки и обслуживания клиентов.",
                    duration: "Любой публичный сервер",
                    users: "5 подключений",
                    tryBtn: "Оформить подписку",
                },
                personal: {
                    description: "Раздели свой VPN-сервер со своей семьей и друзьями.",
                    inAddition: "+ 149 руб. за доп. пользователя",
                    duration: "30 дней",
                    users: "10 подключений",
                    buyBtn: "Оформить подписку",
                    tryBtn: "7 дней бесплатно",
                },
                corporate: {
                    description: "Расширенный уровень технической поддержки и обслуживания клиентов.",
                    inAddition: "+ 299 руб. за доп. пользователя",
                    duration: "30 дней",
                    users: "100 подключений",
                    buyBtn: "Оформить подписку",
                }
            },
            howTo: {
                heading: "Инструкции",
                description: "Как использовать наш сервис",
                hint1: "Более детальные инструкции об использовании сервиса можно найти на странице ",
                hint2: "",
            },
        },
        howTo: {
            heading: "Инструкции",
            description: "Как использовать наш сервис",
            description2: "Выберите секцию, чтобы узнать больше",
            section1: {
                heading: "Первые шаги по использованию бота:",
                start: {
                    heading: "Начало использования",
                    description: "Нажмите на кнопку ниже и отправьте сообщение с командой /start боту.",
                    btn: "Начать использовать бота",
                    timing: "15 сек",
                },
                create: {
                    heading: "Создание вашего сервера",
                    description: "Отправьте команду /servers боту и следуйте его инструкциям.",
                    btn: "Открыть бота",
                    timing: "1 мин",
                },
                getConfig: {
                    heading: "Получить конфигурацию для подключения к серверу",
                    description: "Отправьте команду /configs и следуйте инструкциям бота.",
                    btn: "Получить конфигурацию",
                    timing: "30 сек",
                }
            },
            section2: {
                heading: "Настройка OpenVPN Connect приложения на вашем устройствe:",
                result: "Поздравляем! Вы подключились к серверу.",
                download: {
                    heading: "Скачать Open VPN Connect",
                    description: "Это приложение для подключения к VPN-серверу.",
                    timing: "2 мин",
                },
                notice: {
                    heading: "Внимание:",
                    description: "На вашем устройстве должен быть установлено приложение OpenVPN Connect.",
                },
                setupIphone: {
                    heading: "Настройка подключения к VPN-серверу на IPhone",
                    instruction: {
                        item1: "1. Откройте конфигурацию OpenVPN от бота.",
                        item2: "2. Нажмите \"поделиться\".",
                        item3: "3. Выберите приложение OpenVPN Connect.",
                        item4: "4. Нажмите \"ADD\" и потом \"CONNECT\".",
                    },
                    timing: "1 мин",
                    
                },
                setupAndroid: {
                    heading: "Настройка подключения к VPN-серверу на Android",
                    instruction: {
                        item1: "1. Сохраните в загрузки OpenVPN конфигурацию от бота.",
                        item2: "2. Откройте приложение OpenVPN Connect.",
                        item3: "3. Выберите вкладку \"FILE\".",
                        item4: "4. Выберите каталог \"Download\" из списка и потом \"Telegram\".",
                        item5: "5. Выберите скачанный конфигурационный файл.",
                        item6: "6. Нажмите кнопку \"IMPORT\" и следом кнопку \"ADD\".",
                        item7: "7. Нажмите на переключатель, чтобы подключиться с вашей конфигурацией.",
                        item8: "8. Подтвердите запрос на подключение.",
                    },
                    timing: "1 мин",
                },
                setupMacOs: {
                    heading: "Настройка подключения к VPN-серверу на MacOS",
                    instruction: {
                        item1: "1. Откройте приложение OpenVPN Connect.",
                        item2: "2. Нажмите \"+\", чтобы добавить конфигурацию.",
                        item3: "3. Выберите вкладку \"FILE\".",
                        item4: "4. Скачайте конфигурацию OpenVPN от бота.",
                        item5: "5. Нажмите \"Show in Finder\".",
                        item6: "6. Переместите файл конфигурации в приложение Open VPN Connect.",
                        item7: "7. Нажмите кнопку \"CONNECT\".",
                    },
                    timing: "1 мин",
                },
                setupWindows: {
                    heading: "Настройка подключения к VPN-серверу на Windows",
                    instruction: {
                        item1: "1. Скачайте конфигурацию OpenVPN от бота.",
                        item2: "2. Нажмите правой кнопкой на иконке файла в telegram.",
                        item3: "3. Ввыберите \"Show in folder\".",
                        item4: "4. Нажмите правой кнопкой на файле с конфигурацией.",
                        item5: "5. Выберите \"Открыть с помощью\" и далее выберите OpenVPN Connect.",
                        item6: "6. Нажмите \"OK\".",
                        item7: "7. Нажмите кнопку \"CONNECT\".",
                    },
                    timing: "1 мин",
                },
            },
        },
        contacts: {
            heading: "Наши контакты",
            description: "Свяжитесь с нами и мы поможем вам.",
            telegram: {
                heading: "Вы столкнулись с проблемой..?",
                description: "Мы поможем вам!",
                btn: "Открыть telegram чат поддержки",
            },
            support: {
                heading: "Есть предложение или отзыв?",
                description: "Свяжитесь с нашей поддержкой.",
            },
            sales: {
                heading: "Хотите специальные условия подписки?",
                description: "Или у вас есть вопросы о платежах? Свяжитесь с нашим отделом продаж.",
            }
        },
        footer: {
            heading: "Cloud Dacha помогает технологиям быть комфортными и уютными в повседневной жизни.",
            description: "Без долгих настроек, статей и дополнительной документации.",
            learnMore: {
                heading: "Узнать больше",
            },
            customer: {
                heading: "Для клиентов",
                pp: {
                    heading: "Политика конфиденциальности",
                },
                tos: {
                    heading: "Условия предоставления сервиса",
                },
                eula: {
                    heading: "Пользовательское соглашение",
                },
            },
        },
    },
}

export default translation;