import React, { Component, Suspense } from "react";
import { withTranslation } from 'react-i18next';

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));

const Section = React.lazy(() => import("./Section"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

class HowToUse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                { id: 1, idnm: "home", navheading: 'navItems.home' },
                { id: 5, idnm: "contact", navheading: "navItems.contact" },
            ],
            pos: document.documentElement.scrollTop,
            imglight: false,
            navClass: "",
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        let scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "nav-sticky", imglight: false });
        } else {
            this.setState({ navClass: "", imglight: false });
        }
    };

    //set preloader div
    PreLoader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Suspense fallback={this.PreLoader()}>
                    {/* Importing Navbar */}
                    <Navbar
                        navItems={this.state.navItems}
                        navClass={this.state.navClass}
                        imglight={this.state.imglight}
                    />

                    {/* Importing Section */}
                    <Section />

                    {/* Importing Footer */}
                    <Footer />
                </Suspense>
            </React.Fragment>
        );
    }
}

export default withTranslation()(HowToUse);