import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import EULA from "./pages/EULA/eula";
import Main from "./pages/Main/Main";
import HowToUse from "./pages/HowToUse/HowToUse";
import Contacts from "./pages/Contacts/Contacts";

const routes = [
  
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-of-service", component: TermsOfService },
  { path: "/eula", component: EULA },
  { path: "/how-to-use", component: HowToUse },
  { path: "/contacts", component: Contacts },
  { path: "/", component: Main },
];

export default routes;
