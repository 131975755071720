import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import './i18n';


ReactDOM.render(
  <BrowserRouter>
  <Suspense fallback="loading">
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);